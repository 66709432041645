<template>
  <v-list>
    <v-list-item-group>
      <v-list-item
        three-line
        v-for="(method, index) in methods"
        :key="'t' + index"
        :value="method.grupoId"
        @click="() => $router.push(`/orders/${order.ocId}/payments/${method.grupoId}`).catch(() => {})"
      >
        <v-list-item-avatar size="50">
          <v-img :src="`/img/methods/${method.grupoId}.png`"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-h6 mb-1">
            {{ method.grupo }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
  },
  data() {
    return {
      methods: [],
    };
  },
  mounted() {
    // SE OBTIENEN LOS METODOS DE PAGO ASIGNADOS AL MONTAR EL COMPONENTE
    this.getMethods();
  },
  methods: {
    getMethods() {
      // SE OBTIENE LA ENTIDAD_ID PARA VISUALIZAR LOS METODOS DE PAGO ASIGNADOS
      let ocId = _.get(this.order, "ocId");

      // SE OBTIENE LA RUTA PUBLICA O PRIVADA
      let url = `api/v1`;

      // SE VERIFICA SI EXISTE UN USUARIO EN SESION
      if (this.isAuthenticated) {
        // RUTA DE ACCESO PRIVADO
        url += `/orders/${ocId}/methods`;
      } else {
        // RUTA DE ACCESO PUBLICO
        url += `/public/orders/${ocId}/methods`;
      }

      // SE REALIZA PETICION PARA OBTENER LOS METODOS DE PAGO
      axios
        .get(url)
        .then((res) => {
          // SE OBTIENEN LOS METODOS DE PAGO DE MANERA SEGURA
          let methods = _.get(res, "data.data", []);

          // SE ASIGNAN LOS METODOS DE PAGO A VARIABLE REACTIVA
          this.methods = methods;
        })
        .catch((err) => {
          // SE LIMPIA LA LISTA DE METODOS DE PAGO
          this.methods = [];

          // SE MUESTA EL ERROR PRODUCIDO
          this.$console.error(
            "CHECKOUT => Error getting methods",
            err.toString()
          );
        });
    },
  },
};
</script>